import { Fragment, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PropTypes from "prop-types"

import { Box } from "@mui/material"

import { checkRolePermissions } from "src/utils/checkRolePermissions"

import { usePermissions } from "src/routes/PermissionRoute"

import Nav from "./../Nav"
import Main from "./Main"
import Header from "./Header"

const DashboardLayout = ({ children }) => {
  const [openNav, setOpenNav] = useState(false)
  const navigate = useNavigate()
  const { userPermissions } = usePermissions()

  useEffect(() => {
    if (!userPermissions || !userPermissions?.length) {
      navigate("/login")
    } else {
      const currentPathname = location.pathname

      userPermissions.map((x) => {
        if (currentPathname.includes(x.moduleName)) {
          const operation = currentPathname.includes("edit")
            ? "isEdit"
            : currentPathname.includes("add")
              ? "isAdd"
              : currentPathname.includes("view")
                ? "isView"
                : ""

          if (!checkRolePermissions(userPermissions, x.moduleName, operation)) {
            navigate("/error/403")
          }
        }
      })
    }
  }, [location.pathname])

  return (
    <Fragment>
      <Header onOpenNav={() => setOpenNav(true)} />
      <Box
        sx={{
          minHeight: 1,
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />
        <Main>{children}</Main>
      </Box>
    </Fragment>
  )
}

export default DashboardLayout

DashboardLayout.propTypes = {
  children: PropTypes.node,
}
