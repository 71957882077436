import { useMemo } from "react"
import merge from "lodash/merge"

import { palette as themePalette } from "src/theme/palette"

const palette = themePalette("light")

const CALENDAR_COLOR_OPTIONS = [
  palette.primary.main,
  palette.secondary.main,
  palette.info.main,
  palette.info.darker,
  palette.success.main,
  palette.warning.main,
  palette.error.main,
  palette.error.darker,
]

const useEvent = (events, selectEventId, selectedRange, openForm) => {
  const currentEvent = events.find((event) => event.id === selectEventId)

  const defaultValues = useMemo(
    () => ({
      id: "",
      title: "",
      description: "",
      color: CALENDAR_COLOR_OPTIONS[1],
      allDay: false,
      start: selectedRange ? selectedRange.start : new Date().getTime(),
      end: selectedRange ? selectedRange.end : new Date().getTime(),
    }),
    [selectedRange]
  )

  if (!openForm) {
    return undefined
  }

  if (currentEvent || selectedRange) {
    return merge({}, defaultValues, currentEvent)
  }

  return defaultValues
}

export default useEvent
