import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  draftData: [],
  completedData: [],
}

export const rsvpListSlice = createSlice({
  name: "rsvplist",
  initialState,
  reducers: {
    setRsvpDraftList: (state, action) => {
      state.draftData = action.payload
    },
    setRsvpCompletedList: (state, action) => {
      state.completedData = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setRsvpDraftList, setRsvpCompletedList } = rsvpListSlice.actions

export default rsvpListSlice.reducer
