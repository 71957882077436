import axios from "axios"

import { API_URL } from "../constants/ConfigApi"

const axiosHttp = axios.create({
  baseURL: `${API_URL}`,
})

axiosHttp.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("currentUser")
      localStorage.clear()
      localStorage.setItem('inactive', true)

      if (window.location.href.includes('app')) {
        window.location.reload(1)
      }
    }

    return Promise.reject(error)
  }
)

export default axiosHttp
