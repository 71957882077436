import axiosHttp from "./axiosIntercepter"
import { API_URL, MAIN_API_URL } from "../constants/ConfigApi"
import authHeader from "./auth-header"

const getList = (params) => {
  let url = "auth/user-list?page=" + (params?.page + 1 || 1)

  if (params?.limit) {
    url += "&limit=" + params.limit
  }
  if (params?.search) {
    url += "&search=" + params.search
  }

  return axiosHttp.get(API_URL + url, { headers: authHeader() })
}

const register = (data) => {
  return axiosHttp.post(API_URL + "auth/register", data, { headers: authHeader() })
}

const registerCompany = (data) => {
  const url = MAIN_API_URL + "company"

  return axiosHttp.post(url, data, { headers: authHeader() })
}

const checkDomain = (domain) => {
  const url = MAIN_API_URL + "company/check-subdomain/" + domain

  return axiosHttp.get(url, { headers: authHeader() })
}

const updateUser = (id, data) => {
  return axiosHttp.patch(API_URL + "auth/update-user/" + id, data, {
    headers: authHeader(),
  })
}

const updateProfile = (data) => {
  return axiosHttp.patch(API_URL + "auth/update-profile", data, {
    headers: authHeader(),
  })
}

const login = (data) => {
  return axiosHttp.post(API_URL + "auth/login", data)
}

const changePassword = (data) => {
  return axiosHttp.post(API_URL + "auth/change-password", data, {
    headers: authHeader(),
  })
}

const verifyUser = (token) => {
  return axiosHttp.get(API_URL + "auth/user-activate/" + token)
}

const editUser = (id) => {
  return axiosHttp.get(API_URL + "auth/get-user/" + id, { headers: authHeader() })
}

const getCompanyDetails = () => {
  return axiosHttp.get(API_URL + "auth/company-details", { headers: authHeader() })
}

const getActiveUser = () => {
  return axiosHttp.get(API_URL + "auth/user-profile", { headers: authHeader() })
}

const deleteUser = (id) => {
  return axiosHttp.delete(API_URL + "users/delete/" + id, {
    headers: authHeader(),
  })
}

const forgotPassword = (data) => {
  return axiosHttp.post(API_URL + "auth/forgot-password/", data, {
    headers: authHeader(),
  })
}

const resetPassword = (data) => {
  const token = data.token

  delete data.token

  return axiosHttp.post(API_URL + "auth/reset-password/" + token, data, {
    headers: authHeader(),
  })
}

const refreshToken = () => {
  return axiosHttp.get(API_URL + "auth/new-refresh-token/", {
    headers: authHeader(),
  })
}

const logout = () => {
  const response = axiosHttp.get(API_URL + "auth/logout", {
    headers: authHeader(),
  })

  localStorage.removeItem("currentUser")
  localStorage.clear()

  return response
}

const paymentReceive = () => {
  // "sk_test_tR3PYbcVNZZ796tH88S4VQ2u:"
  const response = axiosHttp.post(
    "https://api.stripe.com/v1/payment_intents",
    {
      amount: 1099,
      currency: "usd",
      payment_method_types: ["card"],
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic c2tfdGVzdF81MU9LemZPU0FLY3JXbUQ3VXlvV0p1WHh5b1FjMkVUMkRzQlI1SXlkcndNQTVpR0c0RmhCY2tMRVYzc2JMYjJxQ3dXeFF3a3lYZTlxNVBOdm9ZTDM5aWw4bTAweGNpQnNKSGo6",
      },
    }
  )

  return response
}

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("currentUser"))
}

const getUserFullname = () => {
  return (
    JSON.parse(localStorage.getItem("currentUser")).firstName +
    " " +
    JSON.parse(localStorage.getItem("currentUser")).lastName
  )
}

export default {
  register,
  registerCompany,
  updateUser,
  login,
  changePassword,
  forgotPassword,
  resetPassword,
  getActiveUser,
  verifyUser,
  logout,
  getList,
  getCurrentUser,
  deleteUser,
  editUser,
  getUserFullname,
  refreshToken,
  updateProfile,
  paymentReceive,
  checkDomain,
  getCompanyDetails,
}
