import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
}

export const formsSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setForm: (state, action) => {
      state.data = action.payload
    },
  },
})

export const { setForm } = formsSlice.actions

export default formsSlice.reducer
