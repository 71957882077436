import { Box } from '@mui/material'

const Separator = () => (
  <Box
    component="span"
    sx={{ width: 4, height: 4, borderRadius: '50%', bgcolor: 'text.disabled' }}
  />
)

export default Separator
