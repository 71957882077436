import PropTypes from "prop-types"
import { useState } from "react"

import Slide from "@mui/material/Slide"
import Input from "@mui/material/Input"
import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import ClickAwayListener from "@mui/material/ClickAwayListener"

import { bgBlur } from "src/theme/css"

import Iconify from "src/components/Iconify"
import i18n from "src/translations"

const HEADER_MOBILE = 64
const HEADER_DESKTOP = 92

const StyledSearchbar = styled("div")(({ theme }) => ({
  ...bgBlur({
    color: theme.palette.background.default,
  }),
  top: 0,
  right: 0,
  zIndex: 99,
  width: "100%",
  display: "flex",
  position: "relative",
  alignItems: "center",
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 2),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up("md")]: {
    height: HEADER_MOBILE,
    padding: theme.spacing(0, 2),
  },
}))

const Searchbar = ({ onSearch, clearSearch }) => {
  const [open, setOpen] = useState(false)
  const [text, setText] = useState("")

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleClose = () => {
    if (open) {
      onSearch(text)
    }
    if (!text) {
      setOpen(false)
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!open && (
          <IconButton onClick={handleOpen}>
            <Iconify icon="eva:search-fill" />
          </IconButton>
        )}
        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              placeholder={`${i18n.t("global:search")}…`}
              value={text}
              onChange={(event) => {
                if (text.trim()?.length > 0 && event.target.value?.length === 0) clearSearch()
                setText(event.target.value)
              }}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: "text.disabled", width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: "fontWeightBold" }}
            />
            {text && text != "" && (
              <Button
                variant="text"
                onClick={() => {
                  setText("")
                  clearSearch()
                }}
              >
                X
              </Button>
            )}
            <Button variant="contained" onClick={handleClose} disabled={text.trim() == ""}>
              {i18n.t("global:search")}
            </Button>
          </StyledSearchbar>
        </Slide>
      </div>
    </ClickAwayListener>
  )
}

export default Searchbar

Searchbar.propTypes = {
  onSearch: PropTypes.func,
  clearSearch: PropTypes.func,
}
