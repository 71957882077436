import { merge } from "lodash"
import PropTypes from "prop-types"

import Chart, { useChart } from "src/components/Chart"

const BarChart = ({ series, options }) => {
  const chartOptions = useChart(
    merge(options, {
      legend: {
        show: false,
        position: "right",
        offsetX: 0,
        offsetY: 50,
      },
      stroke: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      fill: {
        opacity: 1,
      },
    })
  )

  return <Chart dir="ltr" type="bar" series={series || []} options={chartOptions} height={350} />
}

export default BarChart

BarChart.propTypes = {
  series: PropTypes.array,
  options: PropTypes.object,
}
