   
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 data: [],
 activePlans: []
}

export const plansSlice = createSlice({
 name: 'plans',
 initialState,
 reducers: {
  setPlans:(state,action)=>{
    state.data = action.payload; 
  },
  setActivePlans:(state,action)=>{
    state.activePlans = action.payload; 
  }
 },
})

// Action creators are generated for each case reducer function
export const { setPlans, setActivePlans } = plansSlice.actions

export default plansSlice.reducer