export default {
  global: {
    welcome: "Hallo, Willkommen zurück",
    admin: "Administratorin",
    dashboard: "Armaturenbrett",
    events: "Veranstaltungen",
    rsvp: "RSVP",
    connections: "Verbindungen",
    groups: "Gruppen",
    invitation: "Einladung",
    eventOperations: "Veranstaltungsbetrieb",
    master: "Meisterin",
    postEvent: "Post-Event",
    reports: "Berichte",
    attendees: "Teilnehmerinnen",
    tickets: "Tickets",
    checkin: "Einchecken",
    users: "Benutzer",
    eventTypes: "Ereignistypen",
    userTypes: "Benutzertypen",
    roles: "Rollen",
    memberType: "Mitgliedstypen",
    emailTemplate: "E-Mail-Vorlage",
    coupons: "Gutscheine",
    company: "Unternehmen",
    plans: "Pläne",
    home: "heim",
    profile: "Profil",
    logout: "Ausloggen",
    action: "Aktion",
    edit: "Bearbeiten",
    add: "Hinzufügen",
    delete: "entfernen Sie es",
    save: "speichern",
    update: "Aktualisieren",
    view: "sehen",
    search: "finde es heraus",
    cancel: "stornieren",
    preview: "Vorschau",
    deleteMsg: "Möchten Sie wirklich löschen?",
    cancelMsg: "Sind Sie sicher, dass sie abbrechen wollen?",
    name: "Name",
    firstName: "Vorname",
    lastName: "Familienname, Nachname",
    email: "Email",
    phoneNumber: "Telefonnummer",
    phone: "Telefon",
    status: "Status",
    submit: "Einreichen",
    createdOn: "Erstellt am",
    active: "Aktiv",
    inactive: "Inaktiv",
    yes: "Ja",
    no: "NEIN",
    title: "Titel",
  },
  event: {
    selectEventMode: "Wählen Sie den Ereignismodus",
    offline: "Offline",
    online: "Online",
    hybrid: "Hybrid",
    upcoming: "Nächste",
    ongoing: "ist im Gange",
    completed: "Erledigt",
    createEvent: "Erstellen Sie eine Veranstaltung",
    noEventsFound: "Keine Veranstaltungen gefunden",
    duplicate: "Duplikat",
    addEvent: "Fügen Sie ein Ereignis hinzu",
    selectEventType: "Wählen Sie die Art der Veranstaltung aus",
    eventTitle: "Veranstaltungstitel",
    startDate: "Startdatum",
    endDate: "Einsendeschluss",
    eventDescription: "Eventbeschreibung",
    writeSomethingAwesome: "Schreiben Sie etwas Großartiges ...",
    eventInstructions: "Ereignisbenachrichtigungen",
    eventCapacity: "Veranstaltungskapazität",
    paid: "Bezahlt?",
    eventPrice: "Veranstaltungspreis",
    addLocation: "Ort hinzufügen",
    uploadCoverImage: "Laden Sie ein Titelbild hoch",
    uploadFloorPlan: "Laden Sie den Grundriss hoch",
    addSubEvent: "Fügen Sie ein Unterereignis hinzu",
    subEvents: "Unterereignisse",
    location: "Standort",
    eventLocation: "Veranstaltungsort",
    locationRequiredMsg: "Bitte geben Sie den Standort ein",
    multipleLocations: "Mehrere Standorte",
    crewMembers: "Mannschaftsmitglieder",
    selectMemberType: "Wählen Sie einen Mitgliedstyp aus",
    addMember: "Mitglied hinzufügen",
    updateOngoingEventMsg: "Sie können laufende oder vergangene Ereignisse nicht ändern.",
    updateRSVPEventMsg:
      "Bitte löschen Sie RSVP- und andere zugehörige Daten, bevor Sie die Veranstaltung ändern.",
    deleteOngoingEventMsg: "Sie können laufende oder vergangene Ereignisse nicht löschen.",
    deleteRSVPEventMsg:
      "Bitte löschen Sie RSVP- und andere zugehörige Daten, bevor Sie die Veranstaltung löschen.",
    editEvent: "Ereignis bearbeiten",
    free: "Frei",
    about: "Um",
    description: "Beschreibung",
    noDescriptionAdded: "Keine Beschreibung hinzugefügt",
    instructions: "Anweisungen",
    noInstructionsAdded: "Keine Anweisungen hinzugefügt",
    startDateTime: "Startdatum/-uhrzeit",
    endDateTime: "Enddatum/Uhrzeit",
    eventMode: "Ereignismodus",
    selectEventTitle: "Wählen Sie Veranstaltungstitel aus",
    event: "Ereignis",
    importEvent: "Ereignis importieren",
    importRSVP: "RSVP importieren",
    importEventWithRSVP: "Erstellen Sie eine Veranstaltung mit RSVP",
    singleEvent: "Einzelveranstaltung",
    multipleEvents: "Mehrere Ereignisse",
    viewEvent: "Ereignis anzeigen",
    saveAsDraft: "Als Entwurf speichern",
    noLocations: "Keine Standorte",
    noLink: "Keine Verbindung",
    noLinkProvidedbyContact: "Vom Kontakt wurde kein Link bereitgestellt",
    addOns: "Add-ons",
    eventFeatures: "Veranstaltungsfunktionen",
    feedbackForm: "Feedback-Formular",
    feedbackFormDescription:
      "Erstellen Sie benutzerdefinierte Feedback-Formulare, die von den Teilnehmern nach der Veranstaltung ausgefüllt werden",
    agenda: "Agenda",
    agendaDescription: "Definieren Sie die Veranstaltungsagenda und ihre Teammitglieder",
    faqs: "FAQs",
    faqDescription: "Erstellen Sie einen FAQ-Bereich für Veranstaltungszuschauer",
    customRSVP: "Benutzerdefiniertes RSVP",
    customRSVPDescription: "Definieren Sie RSVP-Formulare für die Veranstaltung",
    selectFromExisting: "Wählen Sie aus vorhandenen aus",
    createYourOwn: "Erstelle dein eigenes",
    selectFeedbackForm: "Feedback-Formular auswählen",
    selectAgenda: "Wählen Sie Agenda",
    selectFAQ: "Wählen Sie FAQ aus",
    selectRSVPForm: "Wählen Sie RSVP-Formular",
    requestFeedback: "Feedback anfordern",
    userResponses: "Benutzerantworten",
    noMembersAdded: "Keine Mitglieder hinzugefügt",
    viewingDraftEvent: "Sie sehen sich einen Veranstaltungsentwurf an.",
    reoccurringFromPreviousEvent: "Wiederholt sich ein früheres Ereignis?",
  },
  rsvp: {
    requiredFieldAtleastone: "Bitte wählen Sie mindestens eine Option aus",
    requiredField: "Pflichtfeld",
    published: "Veröffentlicht",
    draft: "Entwurf",
    cancelled: "Abgesagt",
    formDesign: "Formulardesign",
    details: "Einzelheiten",
    createdBy: "Erstellt von",
    copyRSVP: "RSVP kopieren",
    addRSVP: "RSVP hinzufügen",
    editRSVP: "RSVP bearbeiten",
    viewRSVP: "RSVP anzeigen",
    generalSettings: "Allgemeine Einstellungen",
    designForm: "Designform",
    configureReminders: "Konfigurieren Sie Erinnerungen",
    viewPublish: "Anzeigen und veröffentlichen",
    rsvpByDate: "RSVP nach Datum",
    rsvpByTime: "RSVP nach Zeit",
    allowGuestsToDo: "Erlauben Sie Ihren Gästen, dies zu tun",
    individualRSVP: "Individuelle RSVP",
    setLimitForPlusOnes: "Legen Sie ein Limit für Pluspunkte fest",
    mentionPlusOne: "Muss der Haupteingeladene den Namen der Pluspunkte nennen?",
    emailAddressSupport: "E-Mail-Adresse (Support)",
    contactNoSupport: "Kontaktnummer (Support)",
    firstReminderMsg:
      "Richten Sie die erste Erinnerungs-E-Mail für den Hauptteilnehmer ein, um die Namen seiner Pluspunkte einzureichen",
    secondReminderMsg:
      "Legen Sie die Daten für die zweite/letzte Erinnerungs-E-Mail fest, damit der Hauptteilnehmer die Namen seiner Pluspunkte übermitteln kann",
    firstReminderInfo:
      "Diese E-Mail wird als <br /> erste Erinnerung an den primären <br />Teilnehmer gesendet, den Namen <br /> seiner Pluspunkte anzugeben.",
    firstReminderDate: "Erster Erinnerungstermin für primäre Teilnehmerinformationen",
    secondReminderDate: "Zweiter Erinnerungstermin für primäre Teilnehmerinformationen",
    prev: "Vorher",
    next: "Nächste",
    rsvpFormDesign: "RSVP-Formulardesign",
    basic: "Basic",
    custom: "Brauch",
    basicFormFields: "Grundlegende Formularfelder",
    customFormFields: "Benutzerdefinierte Formularfelder",
    selectCustomTemplate: "Wählen Sie Benutzerdefinierte Formularvorlage aus",
    selectTemplate: "Vorlage auswählen",
    pleaseLetUsKnow: "Bitte lassen Sie uns wissen, ob Sie es schaffen können.",
    yesIllBeThere: "Ja, ich werde da sein",
    noIcantMakeIt: "Nein, ich schaffe es nicht",
    useBasicFormMsg: "Ich möchte für meine Veranstaltung das Basisformular verwenden",
    useForm: "Formular verwenden",
    typeAQuestion: "Geben Sie eine Frage ein",
    selectFieldType: "Wählen Sie Feldtyp aus",
    phone: "Telefon",
    textbox: "Textfeld",
    textarea: "Textbereich",
    checkbox: "Kontrollkästchen",
    dropdown: "Runterfallen",
    radioButton: "Radio knopf",
    addFormElement: "Formularelement hinzufügen",
    useCustomFormMsg:
      "Ich möchte für meine Veranstaltung ein benutzerdefiniertes Formular verwenden",
    designEmailTemplates: "Entwerfen Sie Standard- oder wiederkehrende E-Mail-Vorlagen",
    default: "Standard",
    reminder: "Erinnerung",
    messageWhen: "Nachricht wann",
    rsvpIsYes: "RSVP ist Ja",
    rsvpIsNo: "RSVP ist Nein",
    reminderDate1st: "Der erste Erinnerungstermin für Teilnehmerdaten steht vor der Tür",
    reminderDate2nd: "Der 2. Erinnerungstermin für die Teilnehmerdaten steht vor der Tür",
    registrationClosed: "Die Registrierung soll geschlossen werden",
    eventDateApproached: "Der Veranstaltungstermin rückt näher",
    subject: "Thema",
    typeAMessage: "Geben Sie eine Nachricht ein",
    attachPaymentReceipt: "Zahlungsbeleg beifügen",
    sendLinkToNamePlusOnes: "Link senden, um die Pluspunkte zu benennen",
    previewToRsvp: "Eine Vorschau auf das RSVP",
    contactInfo: "Kontaktinformation",
    reservation: "Reservierung",
    guestInfo: "Gästeinfo",
    fillPrimaryInfoTitle: "Aktivieren Sie das Kontrollkästchen, um primäre Details festzulegen",
    publishRSVPPage: "RSVP-Seite veröffentlichen",
    rsvpLinkCopied: "Ihr RSVP-Link wurde kopiert.",
    thankYou: "Danke schön!",
    alreadySubmitted: "Sie haben das RSVP-Formular bereits eingereicht.",
    reachUsForAssistance: "Wenn Sie Hilfe benötigen, können Sie uns erreichen:",
    formSubmitted: "Ihr RSVP-Formular wurde erfolgreich übermittelt.",
    eventAtFullCapacity: "Diese Veranstaltung ist voll ausgelastet.",
    thankYouForYourInterest: "Danke für dein Interesse!",
    rsPerPerson: "Rs/Person",
    total: "Gesamt:",
    payWithCard: "Bezahlen Sie mit Karte",
    payNow: "Zahlen Sie jetzt",
    reasonToRefuse: "Grund zur Ablehnung",
    additionalComments: "Zusätzliche Kommentare / Nennen Sie Ihren Grund",
    dateNotSuitable: "Datum nicht geeignet",
    timeNotSuitable: "Zeit nicht passend",
    priceTooHigh: "Der Veranstaltungspreis ist zu hoch",
    someOtherPlan: "Habe noch andere Pläne",
    notInterested: "Nicht interessiert",
    reasonNotMentioned: "Meine Gründe werden hier nicht genannt",
    rsvpSubmittedSuccessfully: "RSVP erfolgreich übermittelt!",
    errorSubmittingRSVP: "Fehler beim Absenden der RSVP!",
    unauthorizedAccess: "Zugriff unbefugt. Bitte versuche es erneut.",
    rsvpGuestFormSubmitted: "Ihr RSVP-Gastformular wurde erfolgreich übermittelt.",
    guestInfoSubmitted: "Gastinfo erfolgreich übermittelt!",
    errorSubmittingGuestInfo: "Fehler beim Senden der Gastinformationen!",
    primaryAttendeeName: "Name des Hauptteilnehmers",
    eventName: "Veranstaltungsname",
    youCanNotAddGuest: "Sie können für diese Veranstaltung keine Gäste hinzufügen",
    noGuestsAreAdded: "Es werden keine Gäste hinzugefügt",
    addGuest: "Gast hinzufügen",
    yes: "Ja",
    no: "NEIN",
    rsvpBy: "RSVP von",
    fieldOptions: "Feldoptionen",
    addCommaSeperatedOptions: "Fügen Sie durch Kommas getrennte Optionen hinzu",
    basicPlusCustomFormtext:
      "Einfache und benutzerdefinierte Formularfelder werden im RSVP-Formular angezeigt",
  },
  connection: {
    newConnection: "Neue Verbindung",
    upload: "Hochladen",
    remove: "Entfernen",
    website: "Webseite",
    socialMediaLinks: "Social-Media-Links",
    addSocialMediaLink: "Fügen Sie einen Social-Media-Link hinzu",
    editConnection: "Verbindung bearbeiten",
    groupName: "Gruppenname",
    members: "Mitglieder",
    viewMember: "Mitglied anzeigen",
    newGroup: "Neue Gruppe",
    editGroup: "Gruppe bearbeiten",
    groupMembers: "Gruppenmitglieder",
    or: "ODER",
    groupDeleteNote: "Hinweis: Dadurch werden keine Mitglieder gelöscht.",
  },
  invitation: {
    sent: "Gesendet",
    request: "Anfrage",
    date: "Datum",
    sendInvite: "Sende Einladung",
    to: "Zu",
    chooseGroup: "Wählen Sie eine Gruppe",
    eventTitleInfo:
      "Der Veranstaltungstitel wird für die Veranstaltungen angezeigt, für die veröffentlichte RSVP- und Ticketeinstellungen vorhanden sind.",
    send: "Schicken",
    viewInvite: "Einladung anzeigen",
    requested: "Angefordert",
  },
  attendees: {
    totalAmtPaid: "Gesamtbetrag gezahlt",
    maxLimit: "Maximales Limit",
    accepted: "Akzeptiert",
    rejected: "Abgelehnt",
    remaining: "Übrig",
    closingDate: "Einsendeschluss",
    viewAttendee: "Teilnehmer anzeigen",
    payment: "Zahlung",
    plusOnes: "Pluspunkte",
    history: "Geschichte",
    personalInformation: "Persönliche Angaben",
    eventsAttending: "Teilnahme an Veranstaltungen",
    paymentReceipt: "Zahlungsbeleg",
    viewAsPDF: "Als PDF ansehen",
    paymentInformation: "Zahlungsinformationen",
    receiptNo: "Quittung Nr",
    transactionID: "Transaktions-ID",
    transactionDetails: "Transaktionsdetails",
    paymentMethod: "Bezahlverfahren",
    tax: "Steuer",
    dateReceived: "Empfangsdatum",
    subTotal: "Zwischensumme",
    totalPaymentReceived: "Gesamtzahlung erhalten",
    noPaymentReceived: "Keine Zahlung erhalten",
    detailsOfPlusOnes: "Details zu den Pluspunkten",
    noUserAttendeesAvailable: "Keine Benutzerteilnehmer verfügbar",
    historyEmailTransactions: "Verlauf – E-Mail-Transaktionen",
    noEmailLogsAvailable: "Keine E-Mail-Protokolle verfügbar",
  },
  ticket: {
    whatYourTicketShouldConsist: "Woraus sollten Ihre Tickets bestehen?",
    enableAll: "Alle aktivieren",
    qrCodeId: "QR-Code und ID",
    regularVIP: "Regulär/VIP",
    primaryAttendeeNamePlusOne: "Name des Hauptteilnehmers (Plus-Einser zählen)",
    separateTicketforPlusOne: "Separates Ticket für Haupt- und Plus-Eins-Namen",
    mainEventTitle: "Titel des Hauptevents",
    individualEventTitle: "Einzelner Veranstaltungstitel",
    venue: "Veranstaltungsort",
    eventDateTime: "Datum und Uhrzeit des Ereignisses",
    seats: "Sitze",
    notes: "Anmerkungen",
    termsConditions: "Terms & amp; Bedingungen",
    attendeeName: "Name des Teilnehmers",
  },
  checkIn: {
    checkInTime: "Check-in-Zeit",
  },
  user: {
    usertype: "Benutzertyp",
    role: "Rolle",
    password: "Passwort",
    newUser: "Neuer Benutzer",
    editUser: "Benutzer bearbeiten",
    selectUserType: "Wählen Sie Benutzertyp aus",
    selectRole: "Wählen Sie Rolle aus",
  },
  eventType: {
    eventtype: "Ereignistyp",
    newEventType: "Neuer Ereignistyp",
    editEventType: "Ereignistyp bearbeiten",
  },
  userType: {
    newUserType: "Neuer Benutzertyp",
    editUserType: "Benutzertyp bearbeiten",
  },
  coupon: {
    couponSavings: "Coupon-Ersparnisse",
    toPay: "Zu bezahlen",
    applied: "Angewandt!",
    discount: "Rabatt",
    couponCriteriaNotMatch: "Gutscheinkriterien stimmen nicht überein!",
    invalidCoupon: "Ungültiger Gutscheincode! Bitte versuchen Sie es mit einem anderen Code.",
    selectCoupons: "Wählen Sie Gutscheine aus",
    typeCouponCodeHere: "Geben Sie hier den Gutscheincode ein",
    apply: "Anwenden",
    viewCoupons: "Gutscheine ansehen",
    copyCode: "Code kopieren",
    copiedCouponCode: "Gutscheincode kopiert",
    viewDetails: "Details anzeigen",
    paymentCouponForYou: "Zahlungsgutscheine für Sie",
    addCoupon: "Gutschein hinzufügen",
    newCoupon: "Neuer Gutschein",
    editCoupon: "Gutschein bearbeiten",
    validTill: "Gültig bis",
    fixedDiscount: "Fester Rabatt",
    percentageDiscount: "Prozentualer Rabatt",
    noOfTickets: "Anzahl der Tickets",
    couponName: "Gutscheinname",
    couponCode: "Gutscheincode",
    criteria: "Kriterien",
    field: "Feld",
    type: "Typ",
    number: "Nummer",
    condition: "Zustand",
    addMoreCriteria: "Fügen Sie weitere Kriterien hinzu",
  },
  role: {
    roleName: "Rollenname",
    newRole: "Neue Rolle",
    editRole: "Rolle bearbeiten",
    standard: "Standard",
    effectedModules: "Betroffene Module",
    userroles: "Benutzerregeln",
    emailtemplate: "E-Mail-Vorlage",
    invitations: "Einladungen",
  },
  memberType: {
    newMemberType: "Neuer Mitgliedstyp",
    editMemberType: "Bearbeiten Sie den Mitgliedstyp",
  },
  emailTemplate: {
    id: "AUSWEIS",
    keywords: "Schlüsselwörter",
    uniqueIdentifier: "Eindeutige Kennung",
    editEmailTemplate: "E-Mail-Vorlage bearbeiten",
  },
  postEvent: {
    feedback: "Rückmeldung",
    faq: "FAQ",
    agenda: "Agenda",
    customForm: "Benutzerdefiniertes Formular",
    newForm: "Neue Form",
    questions: "Fragen",
    question: "Frage",
    numberOfStar: "Anzahl der Sterne",
    formTitle: "Formulartitel",
    addMoreQuestion: "Weitere Frage hinzufügen",
    forms: "Formen",
    formType: "Formulartyp",
    feedbackFrom: "Feedback-Formular",
    agendaForm: "Agenda-Formular",
    faqForm: "FAQ-Formular",
    customRsvpForm: "Benutzerdefiniertes RSVP-Formular",
    title: "Titel",
    startTime: "Startzeit",
    endTime: "Endzeit",
    hostArtistName: "Name des Gastgebers/Künstlers",
    description: "Beschreibung",
    addMoreHostArtistName:
      "Fügen Sie einen durch Kommas getrennten Wert hinzu, um weitere hinzuzufügen",
    answer: "Antwort",
    agendaSubtitle:
      "Fügen Sie Ihrer Veranstaltung eine Reiseroute, einen Zeitplan oder eine Aufstellung hinzu. Sie können eine Uhrzeit, eine Beschreibung dessen, was passieren wird, und wer während der Veranstaltung Gastgeber sein oder auftreten wird, angeben. (Beispiel: Redner, Künstler, Künstler, Reiseleiter usw.) Wenn Ihre Veranstaltung mehrere Termine hat, können Sie eine zweite Agenda hinzufügen.",
    faqSubtitle:
      "Beantworten Sie Fragen Ihrer Teilnehmer zur Veranstaltung, z. B. zu Parkmöglichkeiten, Zugänglichkeit und Rückerstattungen.",
    addMore: "Weitere hinzufügen",
  },
  reports: {
    selectModule: "Modul auswählen",
    selectFields: "Wählen Sie Felder aus",
    generate: "Generieren",
  },
  profile: {
    account: "Konto",
    general: "Allgemein",
    security: "Sicherheit",
    gender: "Geschlecht",
    male: "Männlich",
    female: "Weiblich",
    address: "Adresse",
    oldPassword: "Altes Passwort",
    newPassword: "Neues Kennwort",
    confirmNewPassword: "Bestätige neues Passwort",
    updatePassword: "Kennwort aktualisieren",
  },
  emptyState: {
    noOptions: "Keine Optionen",
    noRowsFound: "Keine Zeilen gefunden",
    noDataAvailableSelectEvent:
      "Keine Daten verfügbar. Bitte wählen Sie zuerst die Veranstaltung aus.",
    noDataAvailableSelectField: "Keine Daten verfügbar. Bitte wählen Sie zuerst die Felder aus.",
    noTicketsGenerated: "Für diese Veranstaltung werden keine Tickets generiert.",
  },
  upload: {
    uploadPhoto: "Foto hochladen",
    filesAllowed: "Zulässig sind *.jpeg, *.jpg, *.png, *.gif",
    allowedCSV: "Nur *.csv-Datei zulässig",
    downloadCSV: "Laden Sie ein Beispiel-CSV-Format herunter",
    maxSize: "maximale Größe von {{size}}",
    dropOrSelectFile: "Datei ablegen oder auswählen",
    dropFilesHereOrBrowse:
      "Legen Sie Dateien hier ab oder klicken Sie auf <1> Durchsuchen </1> auf Ihrem Computer",
  },
  toast: {
    success: {
      login: "Benutzer hat sich erfolgreich angemeldet",
      logout: "Der Benutzer hat sich erfolgreich abgemeldet.",
      loginAgain: "Bitte melden Sie sich erneut an!",
      inactiveLogout: "Wegen Inaktivität abgemeldet",
      deleteCompany: "Firmendatensatz erfolgreich gelöscht!",
      deleteConnection: "Verbindung erfolgreich gelöscht!",
      deleteGroup: "Gruppe erfolgreich gelöscht!",
      updateGroup: "Gruppe erfolgreich aktualisiert!",
      resetPasswordLink: "Sie erhalten in Kürze einen Link zum Zurücksetzen des Passworts!",
      deleteInvitation: "Einladungsdatensatz erfolgreich gelöscht!",
      deletePlan: "Plan erfolgreich gelöscht!",
    },
    error: {
      postEventList: "Fehler beim Abrufen der Post-Ereignisliste",
      action: "Vorgang konnte nicht ausgeführt werden!",
      companyList: "Fehler beim Abrufen der Firmenliste",
      connectionList: "Fehler beim Abrufen der Verbindungen",
      deleteConnection: "Fehler beim Löschen der Verbindung",
      groupList: "Fehler beim Abrufen der Gruppen",
      deleteGroup: "Fehler beim Löschen der Gruppe",
      selectMember: "Bitte wählen Sie mindestens ein Mitglied aus!",
      checkinList: "Fehler beim Abrufen der Check-in-Liste",
      eventRequiredFields:
        "Bitte füllen Sie die erforderlichen Felder aus: Veranstaltungstyp, Modus, Titel und Datum/Uhrzeit",
      subEventRequiredFields:
        "Bitte füllen Sie die erforderlichen Felder in den Unterveranstaltungen aus: Veranstaltungstyp, Modus, Titel und Datum/Uhrzeit",
      eventCapacity:
        "Die Kapazität der übergeordneten Veranstaltung ist ungültig. Bitte geben Sie einen Wert größer als 0 ein",
      subEventCapacity:
        "Die Kapazität des Unterereignisses ist ungültig. Bitte geben Sie einen Wert größer als 0 ein",
      eventList: "Fehler beim Abrufen von Ereignissen",
      invitationList: "Fehler beim Abrufen der Einladungen",
      invitationRequiredFields:
        "Bitte fügen Sie die E-Mail-Adresse des Empfängers hinzu oder wählen Sie mindestens eine Gruppe aus!",
      invalidCredentials: "Benutzername oder Passwort falsch",
      eventTypeList: "Fehler beim Abrufen der Ereignistypen",
      memberTypeList: "Fehler beim Abrufen des Mitgliedstyps",
      roleList: "Fehler beim Abrufen der Rollen",
      userList: "Fehler beim Abrufen der Benutzer",
      userType: "Fehler beim Abrufen der Benutzertypen",
      plan: "Fehler beim Abrufen der Pläne",
      passwordIncorrect: "Falsches Passwort",
      rsvpList: "Fehler beim Abrufen der RSVP-Liste",
      deleteRSVP: "Fehler beim Löschen des RSVP-Datensatzes",
      rsvpLessThanEvent: "Das RSVP-Datum sollte vor dem Veranstaltungsdatum ({{date}}) liegen.",
      firstReminderLessThanEvent:
        "Das Datum der ersten Erinnerung sollte vor dem Ereignisdatum ({{date}}) liegen.",
      secondReminderGreaterThanFirstReminder:
        "Das Datum der 2. Mahnung sollte nach dem Datum der 1. Mahnung liegen",
      secondReminderLessThanEvent:
        "Das Datum der zweiten Erinnerung sollte vor dem Ereignisdatum ({{date}}) liegen.",
      customFormCheck:
        "Bitte aktivieren Sie das Kontrollkästchen für das benutzerdefinierte Formular",
    },
  },
  company: {
    companyList: "Firmenliste",
    createCompany: "Unternehmen erstellen",
    plan: "Planen",
    planEnd: "Planende",
    domain: "Domain",
    editCompany: "Firma bearbeiten",
    companyName: "Name der Firma",
    companyEmail: "Firmen-E-Mail",
    companyPhone: "Firmentelefonnr.",
    industryType: "Branchentyp",
    noOfEmployee: "Anzahl der Mitarbeiter",
    companyWebsite: "Unternehmenswebseite",
    companyAddress: "Firmenanschrift",
    selectCountry: "Land auswählen",
    selectState: "Staat wählen",
    selectCity: "Stadt wählen",
    selectPlan: "Plan auswählen",
    manageConfig: "Konfiguration verwalten",
    eventCreateLimit: "Begrenzung der Ereigniserstellung",
    connectionLimit: "Verbindungslimit",
    invitationSentLimit: "Einladungslimit senden",
    userCreateLimit: "Beschränkung der Benutzererstellung",
    postEventCreateLimit: "Erstellungslimit für Post-Event-Formular",
    domainSubscription: "Enddatum des Domain-Abonnements",
    publicEventRequestAllow: "Anonyme Anfragen zulassen",
    customFormAllow: "Erlauben Sie die Erstellung benutzerdefinierter Formulare",
    agendaAllow: "Erlauben Sie die Erstellung einer Agenda",
    faqAllow: "Erlauben Sie die Erstellung von FAQs",
    eventFeedbackFormAllow: "Erlauben Sie die Erstellung von Event-Feedback-Formularen",
    passwordValidation: "Das Passwort muss mindestens 8+ sein",
  },
  plan: {
    createPlan: "Plan erstellen",
    editPlan: "Plan bearbeiten",
    planName: "Planname",
    planType: "Plantyp",
    planDetails: "Plandetails",
    monthly: "Monatlich",
    quarterly: "Vierteljährlich",
    biannually: "Alle zwei Jahre",
    yearly: "Jährlich",
    price: "Preis",
    billing: "Abrechnung",
  },
  dashboard: {
    eventsByModes: "Ereignisse nach Modi",
    invited: "Eingeladen",
    requestedSocialMedia: "Angefragt (Soziale Medien)",
    eventTypeStatistics: "Ereignistyp Statistik",
    noOfAttendeeStatistics: "Anzahl der Teilnehmer Statistiken",
    noOfTicketsPurchasedStatistics: "Anzahl der gekauften Tickets Statistik",
    eventBaseTicketAndCheckIn: "Anzahl der Tickets/Check-in basierend auf Veranstaltungen",
    eventBaseTicketAndCheckInInfo:
      "Standardmäßig werden alle Ereignisse in der Vorschau angezeigt, die kein Unterereignis haben. Um Unterereignisse intern zu vergleichen, wählen Sie den Ereignisnamen aus",
    allEvents: "Alle Veranstaltungen",
    paid: "Bezahlt",
  },
  organization: {
    myOrganization: "Meine Organisation",
    companyDetails: "Firmendetails",
    details: "Einzelheiten",
    plan: "Planen",
    help: "Helfen",
    Label: {
      logo: "Logo",
      name: "Name",
      email: "Email",
      contactNo: "Kontakt Nr",
      address: "Adresse",
      domain: "Domain",
      country: "Land",
      state: "Zustand",
      city: "Stadt",
      website: "Webseite",
      noOfEmployee: "Anzahl der Mitarbeiter",
      industryType: "Branchentyp",
      planId: "Planen ID",
      planName: "Planname",
      planStartDate: "Startdatum des Plans",
      planEndtDate: "Enddatum des Plans",
      cancelPlan: "Plan kündigen",
      upgradePlan: "Upgrade-Plan",
      subject: "Thema",
      description: "Beschreibung",
      submit: "Einreichen",
      agendaAllow: "Agenda erlauben",
      customFormAllow: "Benutzerdefiniertes Formular zulassen",
      domainSubscription: "Domain-Abonnement",
      eventFeedbackFormAllow: "Event-Feedback-Formular zulassen",
      eventCreateLimit: "Ereigniserstellungslimit",
      faqAllow: "FAQ Erlauben",
      publicEventRequestAllow: "Öffentliche Veranstaltungsanforderung zulassen",
      invitationSentLimit: "Limit für gesendete Einladungen",
      postEventCreateLimit: "Post-Event-Erstellungslimit",
      connectionLimit: "Verbindungslimit",
      userCreateLimit: "Benutzererstellungslimit",
      current: "Aktuell",
      configurations: "Konfigurationen",
    },
  },
}
