import { Fragment, useEffect } from "react"

import Calendar from "@fullcalendar/react"
import interactionPlugin from "@fullcalendar/interaction"
import listPlugin from "@fullcalendar/list"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import timelinePlugin from "@fullcalendar/timeline"

import { Card } from "@mui/material"

import { useCalendar } from "../hooks"
import { StyledCalendar } from "../styles"
import CalendarToolbar from "../CalendarToolbar"
import useResponsive from "src/hooks/useResponsive"

const CalendarView = ({ events }) => {
  const smUp = useResponsive("up", "sm")

  const {
    calendarRef,
    view,
    date,
    onDatePrev,
    onDateNext,
    onDateToday,
    onChangeView,
    onInitialView,
  } = useCalendar()

  useEffect(() => {
    onInitialView()
  }, [onInitialView])

  return (
    <Fragment>
      <Card>
        <StyledCalendar>
          <CalendarToolbar
            date={date}
            view={view}
            // loading={eventsLoading}
            onNextDate={onDateNext}
            onPrevDate={onDatePrev}
            onToday={onDateToday}
            onChangeView={onChangeView}
          />
          <Calendar
            weekends
            timeZone='UTC'
            selectable
            rerenderDelay={10}
            allDayMaintainDuration
            ref={calendarRef}
            initialDate={date}
            initialView={view}
            dayMaxEventRows={3}
            eventDisplay="block"
            events={events}
            headerToolbar={false}
            // eventClick={openEvent}
            height={smUp ? 720 : "auto"}
            plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
          />
        </StyledCalendar>
      </Card>
    </Fragment>
  )
}

export default CalendarView
