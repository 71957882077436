import 'src/global.css'


import 'react-quill/dist/quill.snow.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Router from 'src/routes/sections'

import ThemeProvider from 'src/theme'

import useScrollToTop from 'src/hooks/useScrollToTop'

import { SnackbarProvider } from './components/Snackbar'
import { PermissionsProvider } from './routes/PermissionRoute'

const App = () => {
  useScrollToTop()

  return (
    <ThemeProvider>
      <SnackbarProvider>
        <PermissionsProvider>
          <Router />
        </PermissionsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
