import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
}

export const attendeesSlice = createSlice({
  name: "attendees",
  initialState,
  reducers: {
    setAttendees: (state, action) => {
      state.data = action.payload
    },
    setAttendeeDetails: (state, action) => {
      state.attendee = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setAttendees, setAttendeeDetails } = attendeesSlice.actions

export default attendeesSlice.reducer
