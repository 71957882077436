   
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 data: [],
}

export const participantSlice = createSlice({
 name: 'participants',
 initialState,
 reducers: {
  setParticipants:(state,action)=>{
    state.data = action.payload; 
  }  
 },
})

// Action creators are generated for each case reducer function
export const { setParticipants } = participantSlice.actions

export default participantSlice.reducer