import { Fragment, useState } from "react"
import PropTypes from "prop-types"

import { Stack, Button, MenuItem, IconButton, Typography, LinearProgress } from "@mui/material"

import { fDate } from "src/utils/formatTime"

import Iconify from "src/components/Iconify"
import CustomPopover from "src/components/CustomPopover"

const VIEW_OPTIONS = [
  { value: "dayGridMonth", label: "Month", icon: "mingcute:calendar-month-line" },
  { value: "timeGridWeek", label: "Week", icon: "mingcute:calendar-week-line" },
  { value: "timeGridDay", label: "Day", icon: "mingcute:calendar-day-line" },
  { value: "listWeek", label: "Agenda", icon: "fluent:calendar-agenda-24-regular" },
]

const CalendarToolbar = ({
  date,
  view,
  loading,
  onToday,
  onNextDate,
  onPrevDate,
  onChangeView,
}) => {

  const [popover, setPopover] = useState(undefined)

  const selectedItem = VIEW_OPTIONS.filter((item) => item.value === view)[0]

  return (
    <Fragment>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 2.5, pr: 2, position: "relative" }}
      >
        <Button
          size="small"
          color="inherit"
          onClick={(event) => setPopover(event.target)}
          startIcon={<Iconify icon={selectedItem.icon} />}
          endIcon={<Iconify icon="eva:arrow-ios-downward-fill" sx={{ ml: -0.5 }} />}
        >
          {selectedItem.label}
        </Button>
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton onClick={onPrevDate}>
            <Iconify icon="eva:arrow-ios-back-fill" />
          </IconButton>
          <Typography variant="h6">{fDate(date)}</Typography>
          <IconButton onClick={onNextDate}>
            <Iconify icon="eva:arrow-ios-forward-fill" />
          </IconButton>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Button size="small" color="error" variant="contained" onClick={onToday}>
            Today
          </Button>
        </Stack>
        {loading && (
          <LinearProgress
            color="inherit"
            sx={{ height: 2, width: 1, position: "absolute", bottom: 0, left: 0 }}
          />
        )}
      </Stack>
      <CustomPopover
        open={popover}
        onClose={() => setPopover(false)}
        arrow="top-left"
        sx={{ width: 160 }}
      >
        {VIEW_OPTIONS.map((viewOption) => (
          <MenuItem
            key={viewOption.value}
            selected={viewOption.value === view}
            onClick={() => {
              setPopover(undefined)
              onChangeView(viewOption.value)
            }}
          >
            <Iconify icon={viewOption.icon} />
            {viewOption.label}
          </MenuItem>
        ))}
      </CustomPopover>
    </Fragment>
  )
}

CalendarToolbar.propTypes = {
  date: PropTypes.object,
  loading: PropTypes.bool,
  onChangeView: PropTypes.func,
  onNextDate: PropTypes.func,
  onPrevDate: PropTypes.func,
  onToday: PropTypes.func,
  view: PropTypes.oneOf(["dayGridMonth", "timeGridWeek", "timeGridDay", "listWeek"]),
}

export default CalendarToolbar
