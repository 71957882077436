import { Fragment, useState } from "react"
import PropTypes from "prop-types"

import { Collapse, Link, Box, ListItemButton } from "@mui/material"
import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { alpha } from "@mui/material/styles"

import { usePathname } from "src/routes/hooks"
import RouterLink from "src/routes/components"

import NavChild from "./NavChild"

const NavItem = ({ item }) => {
  const pathname = usePathname()

  const active = item.path === pathname
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    if (item.path == "") {
      setOpen(!open)
    } else {
      setOpen(false)
    }
  }

  const renderContent = () => (
    <ListItemButton
      onClick={handleOpen}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: "body2",
        color: "text.secondary",
        textTransform: "capitalize",
        fontWeight: "fontWeightMedium",
        ...(active && {
          color: "primary.main",
          fontWeight: "fontWeightSemiBold",
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
          "&:hover": {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
          },
        }),
      }}
    >
      <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {item.icon}
      </Box>
      <Box component="span">{item.title} </Box>
      {item.children && (
        <Box component="span" sx={{ width: 24, height: 24, ml: 2, position: "absolute", right: 2 }}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Box>
      )}
    </ListItemButton>
  )

  return (
    <Fragment>
      {item.path ? (
        <Link component={RouterLink} to={item.path} underline="none">
          {renderContent()}
        </Link>
      ) : (
        renderContent()
      )}
      {item.children && (
        <Collapse in={open} unmountOnExit>
          <NavChild data={item.children} />
        </Collapse>
      )}
    </Fragment>
  )
}

export default NavItem

NavItem.propTypes = {
  item: PropTypes.object,
}
