const authHeader = () => {
	const user = JSON.parse(localStorage.getItem("currentUser"))

	if (user && user.tokens && user.tokens.accessToken) {
		return { "Authorization": `Bearer ${user.tokens.accessToken}` }
	} else {
		return {}
	}
}

export default authHeader
