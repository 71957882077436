export const DEFAULT_API_URL =
  process.env.NODE_ENV && process.env.NODE_ENV === "development"
    ? `http://bharat.jaguar.local:3000/api/v1/`
    : `http://${document.location.host}:3000/api/v1/`

export const MAIN_API_URL = "http://ems.ascentinfo.solutions:3100/api/v1/"

export const API_URL =
  document.location.host.includes("ems.ascentinfo.solutions") || document.location.port === "3100"
    ? MAIN_API_URL
    : DEFAULT_API_URL
