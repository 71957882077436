import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
}

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setFieldData: (state, action) => {
      state.data = action.payload
    }
  },
})

export const { setFieldData } = reportsSlice.actions

export default reportsSlice.reducer
