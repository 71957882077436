import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  data: [],
  memberData: [],
}

export const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    setGroup: (state, action) => {
      state.data = action.payload
    },
    setGroupMember: (state, action) => {
      state.memberData = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setGroup, setGroupMember } = groupSlice.actions

export default groupSlice.reducer
