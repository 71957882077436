import { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"

import { Box, Popover, MenuItem, IconButton } from "@mui/material"

import { LANGS, LANGS_FLAG } from "src/constants/Languages"

const LanguagePopover = () => {
  const { i18n } = useTranslation()
  const { resolvedLanguage } = i18n

  const [open, setOpen] = useState(null)

  const handleOpen = (event) => {
    setOpen(event.currentTarget)
  }

  const handleClose = (lng) => {
    if (typeof lng == "string") {
      i18n.changeLanguage(lng)
    }

    setOpen(null)
  }

  return (
    <Fragment>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: "action.selected",
          }),
        }}
      >
        <img src={LANGS_FLAG[resolvedLanguage]?.icon} alt={LANGS_FLAG[resolvedLanguage]?.label} />
      </IconButton>
      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 180,
          },
        }}
      >
        {LANGS.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === LANGS_FLAG?.resolvedLanguage}
            onClick={() => handleClose(option.value)}
            sx={{ typography: "body2", py: 1 }}
          >
            <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />
            {option.label}
          </MenuItem>
        ))}
      </Popover>
    </Fragment>
  )
}

export default LanguagePopover
