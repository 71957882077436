import { Fragment, useState } from "react"

import {
  Container,
  Typography,
  Autocomplete,
  Stack,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
} from "@mui/material"
import Meta from "src/components/Meta"

import i18n from "src/translations"
import Analytics from "./components/Analytics"
import Iconify from "src/components/Iconify"
import Calendar from "./components/Calendar"
import { format, sub } from "date-fns"

const AppView = () => {
  const translations = {
    eventMode: i18n.t("event:eventMode"),
    startDate: i18n.t("event:startDate"),
    endDate: i18n.t("event:endDate"),
    upcoming: i18n.t("event:upcoming"),
    ongoing: i18n.t("event:ongoing"),
    completed: i18n.t("event:completed"),
    noOptions: i18n.t("emptyState:noOptions"),
    free: i18n.t("event:free"),
    paid: i18n.t("dashboard:paid"),
    offline: i18n.t("event:offline"),
    online: i18n.t("event:online"),
    hybrid: i18n.t("event:hybrid"),
    events: i18n.t("global:events"),
    attendees: i18n.t("global:attendees"),
    eventsByModes: i18n.t("dashboard:eventsByModes"),
    invited: i18n.t("dashboard:invited"),
    requestedSocialMedia: i18n.t("dashboard:requestedSocialMedia"),
    eventTypeStatistics: i18n.t("dashboard:eventTypeStatistics"),
    noOfAttendeeStatistics: i18n.t("dashboard:noOfAttendeeStatistics"),
    noOfTicketsPurchasedStatistics: i18n.t("dashboard:noOfTicketsPurchasedStatistics"),
    eventBaseTicketAndCheckIn: i18n.t("dashboard:eventBaseTicketAndCheckIn"),
    eventBaseTicketAndCheckInInfo: i18n.t("dashboard:eventBaseTicketAndCheckInInfo"),
    allEvents: i18n.t("dashboard:allEvents"),
  }
  const [account] = useState(JSON.parse(localStorage.getItem("currentUser")))
  const [selection, setSelection] = useState("0")
  const [filter, setFilter] = useState("Year")

  const initDate = {
    startDate: format(sub(new Date(), {years: 1}), "MM/dd/yyyy"),
    endDate: format(new Date(), "MM/dd/yyyy"),
  }

  const [filterDate, setFilterDate] = useState(initDate)

  const handleFilterChange = (event, item) => {
    let startDate, endDate
    if (item === "Week" || item === "Month") {
      startDate = format(sub(new Date(), {months: item === "Week" ? 0 : 1, weeks: item === "Week"? 1 : 0}), "MM/dd/yyyy")
      endDate = format(new Date(), "MM/dd/yyyy")
    } else {
      startDate = format(sub(new Date(), {years: 1}), "MM/dd/yyyy")
      endDate = format(new Date(), "MM/dd/yyyy")
    }

    setFilterDate({ startDate, endDate })
    setFilter(item)
  }

  return (
    <Fragment>
      <Meta title={"Dashboard | EventOPS"} />
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              {i18n.t("global:welcome")} 👋, {account?.firstName}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={1} m={1} justifyContent="end">
              {selection == 1 && (
                <Autocomplete
                  disablePortal
                  disableClearable
                  id="filter"
                  size="small"
                  options={["Week", "Month", "Year"]}
                  defaultValue={filter}
                  onChange={handleFilterChange}
                  noOptionsText={translations.noOptions}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ width: "200px" }}
                ></Autocomplete>
              )}
              <ToggleButtonGroup
                value={selection}
                exclusive
                size="small"
                onChange={(event, newValue) => setSelection(newValue)}
              >
                <ToggleButton value="0">
                  <Iconify icon="ion:calendar" />
                </ToggleButton>
                <ToggleButton value="1">
                  <Iconify icon="mdi:analytics" />
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Grid>
        </Grid>
        {selection == 0 && <Calendar />}
        {selection == 1 && (
          <Analytics translations={translations} filter={filter} filterDate={filterDate} />
        )}
      </Container>
    </Fragment>
  )
}

export default AppView
