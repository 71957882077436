import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: [],
}

export const ticketSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setTicketSettings: (state, action) => {
      state.settings = action.payload
    },
    setTickets: (state, action) => {
      state.data = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setTickets, setTicketSettings } = ticketSlice.actions

export default ticketSlice.reducer
