   
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
 data: [],
}

export const userRoleSlice = createSlice({
 name: 'userroles',
 initialState,
 reducers: {
  setUserRoles:(state,action)=>{
    state.data = action.payload; 
  }  
 },
})

// Action creators are generated for each case reducer function
export const { setUserRoles } = userRoleSlice.actions

export default userRoleSlice.reducer