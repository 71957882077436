import axiosHttp from "./axiosIntercepter"
import authHeader from "./auth-header"
import { API_URL } from "../constants/ConfigApi"

const create = (data, action) => {
  return axiosHttp.post(API_URL + action, data, { headers: authHeader() })
}

const edit = (id, action) => {
  return axiosHttp.get(API_URL + action + "/" + id, { headers: authHeader() })
}

const update = (id, data, action) => {
  return axiosHttp.patch(API_URL + action + "/" + id, data, { headers: authHeader() })
}

const deletes = (id, action) => {
  return axiosHttp.delete(API_URL + action + "/" + id, { headers: authHeader() })
}

const getEvents = (
  emode,
  isRsvp = false,
  draft = false,
  isTickets = false,
  viewOpt = undefined
) => {
  let url = "events/get-events/" + emode

  if (isRsvp) {
    url = url + "?rsvp=" + isRsvp
  }
  if (draft) {
    url = url + "?draft=" + draft
  }
  if (isTickets) {
    url = url + "?tickets=" + isTickets
  }
  if (viewOpt) {
    url = url + "&view=" + viewOpt
  }

  return axiosHttp.get(API_URL + url, { headers: authHeader() })
}

const getAllEvents = (isMultiple= false, month = undefined) => {
  let url = "events/get-all-events?multiple=" + isMultiple

  if (month) {
    url = url + "&month=" + month
  }

  return axiosHttp.get(API_URL + url, { headers: authHeader() })
}

const getEventDetail = (id) => {
  return axiosHttp.get(API_URL + "events/get-event-details/" + id, { headers: authHeader() })
}

const getFeedbackDetails = (id) => {
  return axiosHttp.get(API_URL + "feedback/get-event-feedback/" + id, { headers: authHeader() })
}

const getChildEventsById = (peid) => {
  return axiosHttp.get(API_URL + "events/get-child-events-by-id/" + peid, { headers: authHeader() })
}

const getChildEventsWithRsvpById = (peid) => {
  return axiosHttp.get(API_URL + "events/get-child-events-by-id/" + peid, {
    headers: authHeader(),
  })
}

const getEventParticipatesById = (peid) => {
  return axiosHttp.get(API_URL + "events/get-event-participates-by-id/" + peid, {
    headers: authHeader(),
  })
}

const createEvent = (data) => {
  let action = "events/create"

  return create(data, action)
}

const editEvent = (id) => {
  let action = "events/get-event-details"

  return edit(id, action)
}

const editAdditionalParticipates = (id) => {
  let action = "events/get-event-participates-by-id"

  return edit(id, action)
}

const editChildEvent = (id) => {
  let action = "events/get-child-events-by-id"

  return edit(id, action)
}

const updateEvent = (data) => {
  let action = "events/update"
  const id = data.id

  delete data.id

  return update(id, data, action)
}

const updateChildEvent = (data) => {
  let action = "events/update-child-event"
  const id = data.id

  delete data.id

  return update(id, data, action)
}

const updateAdditonalParticipants = (data) => {
  let action = "events/update-additional-participants"
  const id = data.id

  delete data.id

  return update(id, data, action)
}

const deleteEventP = (id) => {
  let action = "events/delete-parent-event"

  return deletes(id, action)
}

const deleteEventC = (id) => {
  let action = "events/delete-child-event"

  return deletes(id, action)
}

const deleteEventParticipates = (id) => {
  let action = "events/delete-event-participates"

  return deletes(id, action)
}

const updateEventWithAddons = (id, data) => {
  return axiosHttp.patch(API_URL + "events/event-feedback-update/" + id, data, {
    headers: authHeader(),
  })
}
const feedbackRequest = (id) => {
  return axiosHttp.get(API_URL + "events/send-feedback-email/" + id, {
    headers: authHeader(),
  })
}

const getDashboard = (data) => {
  let action = "dashboard/get-dashboard"

  return create(data, action)
}

export default {
  createEvent,
  getEvents,
  getAllEvents,
  getChildEventsById,
  getChildEventsWithRsvpById,
  getEventDetail,
  getFeedbackDetails,
  getEventParticipatesById,
  deleteEventP,
  deleteEventC,
  deleteEventParticipates,
  editEvent,
  editAdditionalParticipates,
  editChildEvent,
  updateEvent,
  updateChildEvent,
  updateAdditonalParticipants,
  updateEventWithAddons,
  feedbackRequest,
  getDashboard,
}
