import PropTypes from "prop-types"

import { Card, Typography, Stack, Box, Divider } from "@mui/material"

import { fNumber, fPercent } from "src/utils/formatNumber"

import Iconify from "src/components/Iconify"
import Label from "../Label"

const Summary = ({ title, items, sx, ...other }) => (
  <Card
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      p: 2,
      ...sx,
    }}
    {...other}
  >
    <Typography variant="subtitle2">{title}</Typography>
    <Stack spacing={1} direction="row" justifyContent="space-between">
      {items.map(({ label, total, percent }, index) => (
        <Stack key={label} spacing={1} justifyContent="center" alignItems="center" direction="row">
          <Stack direction="column" alignItems="center" sx={{ mt: 2, mb: 1 }} p={4}>
            {percent && (
              <Label
                startIcon={
                  <Iconify
                    icon={
                      percent < 0
                        ? "solar:double-alt-arrow-down-bold-duotone"
                        : "solar:double-alt-arrow-up-bold-duotone"
                    }
                  />
                }
                variant="soft"
                color={percent < 0 ? "error" : "success"}
              >
                <Typography component="div" variant="subtitle2">
                  {percent > 0 && "+"}
                  {fPercent(percent)}
                </Typography>
              </Label>
            )}
            <Typography variant="h4">{fNumber(total)}</Typography>
            <Typography variant="caption">{label}</Typography>
          </Stack>
          {index < items.length - 1 && <Divider orientation="vertical" sx={{ height: "100px" }} />}
        </Stack>
      ))}
    </Stack>
  </Card>
)

export default Summary

Summary.propTypes = {
  sx: PropTypes.object,
  title: PropTypes.string,
  items: PropTypes.array,
}
